<template>
  <div class="processedTaskList">
    <!-- 顶部导航 -->
    <commonNavBar :title="'已处理的'"></commonNavBar>
    <!-- 搜索框 -->
    <form action="/">
      <van-search
        v-model="value"
        show-action
        :placeholder="placeholder"
        left-icon=""
      >
        <template #action>
          <div>搜索</div>
        </template>
        <template #label>
          <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item
              v-model="condition"
              :options="option1"
            />
          </van-dropdown-menu>
        </template>
      </van-search>
    </form>
    <!-- 下拉框 -->
    <van-dropdown-menu
      active-color="#1989fa"
      :close-on-click-outside="flag"
    >
      <van-dropdown-item :title="title">
        <van-cell
          clickable
          title="全部"
          @click="toggleAll"
        >
          <template #right-icon>
            <van-checkbox
              name="all"
              ref="all"
              v-model="checked"
            />
          </template>
        </van-cell>
        <van-checkbox-group
          v-model="result"
          ref="checkboxGroup"
        >
          <van-cell-group>
            <van-cell
              clickable
              v-for="item in list"
              :key="item.id"
              :title="item.name"
              @click="toggle(item.id)"
            >
              <template #right-icon>
                <van-checkbox
                  :name="item"
                  :ref="item.id"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </van-dropdown-item>
      <van-dropdown-item
        v-model="value3"
        :options="option2"
        title="任务状态"
      />
      <van-dropdown-item
        title="更多"
        ref="item"
      >
        <div class="selectBox">
          <p class="title">所属区县</p>
          <div>
            <van-checkbox-group
              v-model="result1"
              direction="horizontal"
              ref="checkboxGroup1"
            >
              <van-checkbox name="all">
                <template #icon="props">
                  <div>
                    <van-button
                      :color="!props.checked ? '#969799' : '#3ea7f0'"
                      size="mini"
                      @click="allClick"
                    >全部</van-button>
                  </div>
                </template>
              </van-checkbox>
              <van-checkbox name="1">
                <template #icon="props">
                  <div>
                    <van-button
                      :color="!props.checked ? '#969799' : '#3ea7f0'"
                      size="mini"
                    >全部1</van-button>
                  </div>
                </template>
              </van-checkbox>
              <van-checkbox name="2">
                <template #icon="props">
                  <div>
                    <van-button
                      :color="!props.checked ? '#969799' : '#3ea7f0'"
                      size="mini"
                    >全部2</van-button>
                  </div>
                </template>
              </van-checkbox>
              <van-checkbox name="3">
                <template #icon="props">
                  <div>
                    <van-button
                      :color="!props.checked ? '#969799' : '#3ea7f0'"
                      size="mini"
                    >全部3</van-button>
                  </div>
                </template>
              </van-checkbox>
              <van-checkbox name="4">
                <template #icon="props">
                  <div>
                    <van-button
                      :color="!props.checked ? '#969799' : '#3ea7f0'"
                      size="mini"
                    >全部4</van-button>
                  </div>
                </template>
              </van-checkbox>
            </van-checkbox-group>
          </div>
          <p class="title">发起人</p>
          <van-field
            is-link
            readonly
            clickable
            name="initiator"
            placeholder="请选择发起人"
            :value="initiator"
            @click="$router.push('/commonAddressBook')"
          />
          <p class="title">发起时间</p>
          <van-field
            is-link
            readonly
            clickable
            name="initiationTime"
            :value="initiationTime"
            placeholder="选择日期区间"
            @click="initiationTimeHandler"
          />
          <p class="title">更新时间</p>
          <van-field
            is-link
            readonly
            clickable
            name="updateTime"
            :value="updateTime"
            placeholder="选择日期区间"
            @click="updateTimeHandler"
          />
          <div class="myBottom">
            <van-button
              type="default"
              @click="resetForm"
            >重置</van-button>
            <van-button type="info">确定</van-button>
          </div>
        </div>
      </van-dropdown-item>
      <van-dropdown-item
        v-model="value3"
        :options="option2"
      />
    </van-dropdown-menu>
    <!-- 单位列表 -->
    <div
      class="taskList"
      v-watermark
    >
      <div class="taskCard">
        <div class="top">
          <div class="taskType">任务类型</div>
          <div class="taskLabel">
            <van-tag type="primary">标签</van-tag>
            <van-tag type="success">标签</van-tag>
            <van-tag type="danger">标签</van-tag>
            <van-tag type="warning">标签</van-tag>
          </div>
          <div class="tasktime">16:00</div>
        </div>
        <div class="connent">
          <div class="info">
            <div class="item">
              <span class="label">任务单号:</span>
              <span class="value">WXYJ933020201120001</span>
            </div>
            <div class="item">
              <span class="label">单位编号:</span>
              <span class="value">9339</span>
            </div>
            <div class="item">
              <span class="label">单位名称:</span>
              <span class="value">上海易达通信有限公司上海易达通信有限公司上海易达通信有限公司上海易达通信有限公司</span>
            </div>
            <div class="item">
              <span class="label">所属区县:</span>
              <span class="value">虹口区</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="userPic">
            <span>杨洋</span>
          </div>
          <div class="taskInfo">部门名称 发起人 发起</div>
          <div class="taskProcess">
            <van-tag type="warning">任务状态</van-tag>
          </div>
        </div>
      </div>
      <div class="taskCard"></div>
      <div class="taskCard"></div>
      <div class="taskCard"></div>
      <div class="taskCard"></div>
    </div>
    <!-- 发起时间日历选择弹框 -->
    <van-calendar
      title="发起时间选择"
      ref="initiationTimePicker"
      v-model="showPicker"
      type="range"
      @confirm="onConfirm"
      allow-same-day
      color="#3ea7f0"
      :min-date="minDate"
      :max-date="maxDate"
      @closed="flag = true"
    />
    <!-- 发起时间日历选择弹框 -->
    <van-calendar
      title="更新时间选择"
      ref="updateTimePicker"
      v-model="showUpdateTimePicker"
      type="range"
      @confirm="onUpdateTimeConfirm"
      allow-same-day
      color="#3ea7f0"
      :min-date="minDate"
      :max-date="maxDate"
      @closed="flag = true"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'processedTaskList',
  data() {
    return {

      value: '',
      condition: 0,
      option1: [
        { text: '单位', value: 0 },
        { text: '任务单', value: 1 }
      ],
      value2: 0,
      value3: 'a',
      option4: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' }
      ],
      option3: [
        { text: '默认', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' }
      ],
      result: [],
      result1: [],
      checked: false,
      title: '任务类型',
      list: [
        { id: 1, name: '任务类型1' },
        { id: 2, name: '任务类型2' },
        { id: 3, name: '任务类型3' }
      ],
      initiationTime: '',
      updateTime: '',
      showPicker: false,
      showUpdateTimePicker: false,
      flag: true
    }
  },
  activated() {
    this.$refs.item.toggle()
    this.$nextTick(() => {
      this.$refs.item.toggle()
    })
  },
  methods: {
    ...mapMutations('addressBook', ['setSelectList']),
    toggle(val) {
      this.$refs[val][0].toggle()
    },
    toggleAll() {
      this.$refs.all.toggle()
      setTimeout(() => {
        if (this.checked) {
          this.$refs.checkboxGroup.toggleAll(true)
        } else {
          this.$refs.checkboxGroup.toggleAll(false)
        }
      }, 0)
    },
    allClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.result1.includes('all')) {
        this.$refs.checkboxGroup1.toggleAll(false)
      } else {
        this.$refs.checkboxGroup1.toggleAll(true)
      }
    },
    // 打开更新时间弹框
    updateTimeHandler() {
      this.showUpdateTimePicker = true
      this.flag = false
    },
    // 打开发起时间弹框
    initiationTimeHandler() {
      this.showPicker = true
      this.flag = false
    },
    // 选择发起时间区间
    onConfirm(date) {
      const [start, end] = date
      this.showPicker = false
      this.initiationTime = `${dayjs(start).format('YYYY/MM/DD')} - ${dayjs(
        end
      ).format('YYYY/MM/DD')}`
    },
    // 选择更新时间区间
    onUpdateTimeConfirm(date) {
      const [start, end] = date
      this.showUpdateTimePicker = false
      this.updateTime = `${dayjs(start).format('YYYY/MM/DD')} - ${dayjs(
        end
      ).format('YYYY/MM/DD')}`
    },
    // 重置表单
    resetForm() {
      this.setSelectList([])
      this.initiationTime = ''
      this.updateTime = ''
      this.result1 = []
      this.$refs.initiationTimePicker.reset()
      this.$refs.updateTimePicker.reset()
    }
  },
  watch: {
    result(val) {
      if (val.length === 3) {
        this.checked = true
      } else {
        this.checked = false
      }
      if (val.length === 1) {
        this.title = val[0].name
      } else if (!val.length) {
        this.title = '任务类型'
      } else {
        this.title = '类型(多选)'
      }
    },
    result1(val) {
      if (val.length === 4 && !val.includes('all')) {
        this.result1.push('all')
      }
      if (val.length !== 5 && val.includes('all')) {
        this.result1 = this.result1.filter((item) => {
          return item !== 'all'
        })
      }
    }
  },
  computed: {
    ...mapState('addressBook', ['selectList']),
    placeholder() {
      if (this.condition === 0) {
        return '请输入单位编号或名称'
      } else {
        return '请输入任务单号'
      }
    },
    initiator() {
      const list = this.selectList.map((item) => {
        return JSON.parse(item).name
      })
      return list.toString()
    },
    minDate() {
      return new Date(1970, 0, 1)
    },
    maxDate() {
      return new Date()
    }
  }
}
</script>
<style lang="scss" scoped>
.processedTaskList {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  ::v-deep {
    .van-search {
      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .van-dropdown-menu__bar {
      box-shadow: none;
    }

    .van-calendar__popup.van-popup--bottom,
    .van-calendar__popup.van-popup--top {
      height: 85% !important;
    }

    .selectBox {
      padding: 10px;

      .van-checkbox__icon {
        height: auto;
      }

      .van-checkbox-group {
        justify-content: flex-start;
      }

      .van-checkbox--horizontal {
        width: 25%;
        margin: 0;
        justify-content: center;

        .van-button {
          width: 58px;
        }
      }

      .title {
        font-size: 14px;
        margin-top: 5px;
      }

      .van-field__control {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .myBottom {
        display: flex;
        justify-content: space-between;

        .van-button {
          width: 48%;
          height: 35px;
        }
      }
    }

    .taskList {
      height: calc(100% - 54px - 48px - 46px);
      overflow: auto;
      padding: 5px;

      .taskCard {
        height: 200px;
        margin-bottom: 10px;
        overflow: hidden;
        box-shadow: 0 0 9px 2.5px #cdc9c9;
        border-radius: 5px;

        .top {
          height: 30px;
          display: flex;
          padding: 0 5px;

          .taskType {
            font-size: 16px;
            line-height: 30px;
            margin-right: 5px;
          }

          .taskLabel {
            flex: 1;
            overflow: auto;
            display: flex;
            align-items: center;

            .van-tag {
              height: 17px;
              margin-right: 5px;
            }
          }

          .tasktime {
            font-size: 12px;
            line-height: 30px;
          }
        }

        .connent {
          padding: 0 5px;
          height: calc(100% - 30px - 30px);

          .info {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;

            .item {
              display: flex;
              color: #999999;
              flex-wrap: nowrap;

              .label {
                font-size: 12px;
                width: 60px;
              }

              .value {
                font-size: 12px;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }

        .bottom {
          height: 30px;
          padding: 0 5px;
          display: flex;

          .userPic {
            position: relative;
            height: 100%;
            width: 21px;

            >span {
              display: inline-block;
              width: 50px;
              height: 50px;
              background-color: #1990ff;
              color: white;
              border-radius: 50%;
              line-height: 50px;
              text-align: center;
              font-size: 20px;
              vertical-align: middle;
              top: 50%;
              left: 50%;
              transform: scale(0.33);
              position: absolute;
              top: -10px;
              left: -15px;
            }
          }

          .taskInfo {
            font-size: 14px;
            flex: 1;
            overflow: auto;
            line-height: 30px;
          }

          .taskProcess {
            line-height: 30px;
            display: flex;
            align-items: center;

            .van-tag {
              height: 17px;
            }
          }
        }
      }
    }
  }
}
</style>
